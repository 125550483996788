@import url("http://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800");

// Override default variables before the import
$primary: #2b1456;
$secondary: #ff2b98;

$body-color: white; //this is the text color
$body-bg: $primary;

$dark: #170539;
$light: #af93f2;

$input-border-color: #d0d0d0;

$font-family-base: "Poppins", sans-serif !important;

$theme-colors: (
  "light-gray": #f2f2f2,
);

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-icons/font/bootstrap-icons.css";

body {
  background-image: url("./imgs/background.png");
  background-position: top;
  background-repeat: no-repeat;
}
.page {
  margin-top: 2 * $spacer;
  margin-bottom: 2 * $spacer;
}

.user-pic {
  height: 40px;
  width: 40px;
}

.user-img {
  width: 100px;
  height: 100px;
  border-radius: 100px;
}

.item-img {
  height: 150px;
  object-fit: cover;
}

.crop-text-3 {
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.user-info {
  min-width: 800px;
}
